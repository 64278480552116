<template>
  <div class="divBody">
    <MyHeader :title="$t('lang.menu_queryreport')" />
    <div class="divPanel">
    <div class="divTitle">{{$t("lang.menu_query")}}</div>
    <van-grid :column-num="3" :border="false" icon-size="48px">
      <van-grid-item
        icon="/img/plan.png"
        is-link
        :to="{ name: 'PollingPlan', params: { p: { pagesize: 1 } } }"
        :text="$t('lang.menu_patrolplanrecord')"
      ></van-grid-item>
      <van-grid-item
        icon="/img/query.png"
        is-link
        :to="{ name: 'PollingQuery', params: { p: { pagesize: 1 } } }"
        :text="$t('lang.menu_patrolrecord')"
      ></van-grid-item>
      <van-grid-item
        icon="/img/miss.png"
        is-link
        :to="{ name: 'MissRecordAudit', params: { p: { pagesize: 1 } } }"
        :text="$t('lang.menu_patrolmissrecord')"
      ></van-grid-item>
      <van-grid-item
        icon="/img/even.png"
        is-link
        :to="{ name: 'ItemQuery', params: { p: { pagesize: 1 } } }"
        :text="$t('lang.menu_patrolevent')"
      ></van-grid-item>
      <van-grid-item
        icon="/img/temp.png"
        is-link
        :to="{ name: 'TempPatrolQuery', params: { p: { pagesize: 1 } } }"
        :text="$t('lang.menu_temppatrolquery')"
      ></van-grid-item>
      <van-grid-item></van-grid-item>
    </van-grid>
    </div>

    <div class="divPanel">
    <div class="divTitle">{{$t("lang.menu_map")}}</div>
    <van-grid :column-num="3" :border="false" icon-size="48px">
      <van-grid-item
        icon="/img/line.png"
        is-link
        :to="{ name: 'Map', params: { p: { pagesize: 1 } } }"
        :text="$t('lang.menu_patrolmap')"
      ></van-grid-item>
      <van-grid-item
        icon="/img/map2.png"
        is-link
        :to="{ name: 'CurrLocal', params: { p: { pagesize: 1 } } }"
        :text="$t('lang.menu_currlocal')"
      ></van-grid-item>
      <van-grid-item></van-grid-item>
    </van-grid>
    </div>

    <div class="divPanel">
    <div class="divTitle">{{$t("lang.menu_report")}}</div>
    <van-grid :column-num="3" :border="false" icon-size="48px">
      <van-grid-item
        icon="/img/total1.png"
        is-link
        :to="{ name: 'Pass', params: { p: { pagesize: 1 } } }"
        :text="$t('lang.menu_passrate')"
      ></van-grid-item>
      <van-grid-item
        icon="/img/total2.png"
        is-link
        :to="{ name: 'Unusual', params: { p: { pagesize: 1 } } }"
        :text="$t('lang.menu_abnormal')"
      ></van-grid-item>
      <van-grid-item></van-grid-item>
    </van-grid>
    </div>
  </div>
</template>

<style scoped>
.van-grid-item__content{padding:10px 5px !important;}
.divBody{padding:10px;}
.divPanel{border-radius: 10px;padding:5px;background-color: white;margin-bottom:10px;}
.divTitle{font-size:16px;color:#333;font-weight:bold;padding:5px;}
</style>

<script>
import MyHeader from "@/components/Header.vue";
export default {
  components: { MyHeader },
  beforeCreate() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#33999960");
  },
  //#0184b2
  beforeDestroy() {
    document.querySelector("body").setAttribute("style", "");
  }
};
</script>
